<template>
	<section
		class="figure"
		:class="centerImage && 'center'"
	>
		<LinkWrap
			v-bind="link"
			customType="figure"
			class="image"
			v-if="link"
		>
			<ImageResponsive
				v-if="image"
				v-bind="image"
				:auto-aspect="hasMultipleSources"
			/>
			<figcaption
				v-if="caption"
				class="margin-top-s"
			>
				<span
					v-if="caption.header"
					class="caption-header"
					>{{ caption.header }}</span
				>
				<span
					v-if="caption.body"
					class="caption-body"
					>{{ caption.body }}</span
				>
			</figcaption>
		</LinkWrap>
		<figure v-else>
			<ImageResponsive
				v-if="image"
				v-bind="image"
				:auto-aspect="hasMultipleSources"
			/>
			<figcaption
				v-if="caption"
				class="margin-top-s"
			>
				<span
					v-if="caption.header"
					class="caption-header"
					>{{ caption.header }}</span
				>
				<span
					v-if="caption.body"
					class="caption-body"
					>{{ caption.body }}</span
				>
			</figcaption>
		</figure>
	</section>
</template>

<script>
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";
import ResponsiveImageViewModel from "~/helpers/ViewModels/ResponsiveImageViewModel.js";

import GizmoComponentMixin from "~/mixins/platform/GizmoComponentMixin.js";
import striptags from "striptags";

export default defineNuxtComponent({
	name: "ImageWithCaption",
	mixins: [GizmoComponentMixin],

	props: {
		component: {
			type: Object,
		},
	},

	computed: {
		link() {
			return this.content.link && LinkViewModel(this.content.link);
		},
		image() {
			return this.content.image && ResponsiveImageViewModel(this.content.image);
		},
		centerImage() {
			return this.content.centerImage;
		},
		hasMultipleSources() {
			return this.content.image && Object.keys(this.content.image).length > 2;
		},
		caption() {
			if (this.component.content.captionHeader || this.component.content.captionBody) {
				return {
					header: this.component.content.captionHeader,
					body: this.component.content.captionBody
						? striptags(this.component.content.captionBody)?.replace(/&nbsp;/g, "")
						: "",
				};
			}
			return null;
		},
	},
});
</script>

<style lang="scss" scoped>
.figure {
	@include font-text-s;

	&.center {
		text-align: center;
	}

	.image {
		display: inline-block;
		text-align: left;
		max-width: 100%;

		:deep(img) {
			width: 100%;
		}
	}

	figure.link-wrap {
		cursor: default;
	}

	figcaption {
		color: $color-neutrals-600-shade;
	}

	.caption-header {
		font-weight: bold;
	}

	.caption-body {
		font-family: $font-family-telenor-ui;
	}
}
</style>
